import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";
import styled from "styled-components"; // Import styled-components

import {
  Box, useMediaQuery, useTheme, Button
} from "@mui/material";
// Define types for API response
interface Prize {
  id: number;
  url: string;
  name: string;
  productId: string;
  productImageUrl?: string 
}

interface Winner {
  firstName: string;
  created: string;
  prizes: Prize[];
}

// Styled Components for CSS-in-JS
const CarouselContainer = styled.div`
  max-width: 90vw;
  margin: 0 auto;
  background: url('') no-repeat center;
  background-size: cover;
  padding: 20px;
  overflow:hidden !important;
`;
  //border-radius: 10px;
  //box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);

const SlideItem = styled.div`
  text-align: center;
  padding: 15px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const PrizeImage = styled.img`
  max-width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
`;

const PrizeName = styled.h3`
  font-size: 16px;
  margin: 10px 0;
  color: #e600ff;
  font-weight: bold;
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis; /* Adds "..." when text is too long */

`;

const WinnerText = styled.p`
  font-size: 14px;
  color: #555;
`;

const ClawButton = styled.a`
  display: inline-block;
  margin-top: 10px;
  padding: 8px 15px;
  background: #ff0055;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px;

  &:hover {
    background: #cc0044;
  }
`;

const LoadingText = styled.div`
  text-align: center;
  font-size: 18px;
  padding: 20px;
`;

  
const RecentWinnersCarousel: React.FC = () => {
  const [winners, setWinners] = useState<Winner[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen size is mobile

  useEffect(() => {
    const fetchRecentWins = async () => {
      try {
        const response = await fetch("https://claw-api.d8superstore.com/recent_wins");
        const data: Winner[] = await response.json();
        setWinners(data);
      } catch (error) {
        console.error("Error fetching recent wins:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecentWins();
  }, []);

  if (isLoading) {
    return <LoadingText>Loading recent wins...</LoadingText>;
  }


  // Function to convert datetime to "time since" format
    /**
   * Converts a timestamp into "time since" format
   * @param timestamp ISO datetime string
   * @returns A human-readable string like "5 minutes ago"
   */
    function timeSince(timestamp: string): string {
      const now = new Date();
      const date = new Date(timestamp);
      const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  
      if (seconds < 60) return `${seconds} seconds ago`;
      const minutes = Math.floor(seconds / 60);
      if (minutes ==1) return `${minutes} minute ago`;
      if (minutes < 60) return `${minutes} minutes ago`;
      const hours = Math.floor(minutes / 60);
      if (hours ==1) return `${hours} hour ago`;
      if (hours < 24) return `${hours} hours ago`;
      const days = Math.floor(hours / 24);
      if (days ==1) return `${days} day ago`;
      if (days < 30) return `${days} days ago`;
      const months = Math.floor(days / 30);
      if (months ==1) return `${months} month ago`;
      if (months < 12) return `${months} months ago`;
      const years = Math.floor(days / 365);
      return `${years} years ago`;
    }



  return (
    <CarouselContainer
    style={{
      maxWidth: isMobile ? '100%' : 'calc(100vw - 640px'

    }}
    
    
    >
      <Splide
        options={{
          type: winners.length > 3 ? "loop" : "slide",
          perPage: Math.min(5, winners.length),
          perMove: 1,
          autoplay: true,
          interval: 3000,
          pauseOnHover: true,
          gap: "20px",
          pagination: false,
          arrows: true,
          breakpoints: {
            1024: { perPage: Math.min(5, winners.length) },
            768: { perPage: 1 },
          },
        }}
      >
        {winners.flatMap((winner) =>
          winner.prizes.map((prize, index) => (
            <SplideSlide key={`${winner.firstName}-${index}`}>
              <SlideItem>
                <PrizeImage
                  src={prize.productImageUrl ? prize.productImageUrl : 'https://d8superstore.com/wp-content/uploads/2024/10/BRB3NC_04-1024x1024.jpg'}
                  alt={prize.name}
                />
                <PrizeName>{prize.name}</PrizeName>
                <WinnerText>Won by: <strong>{winner.firstName}</strong></WinnerText>
                <div><strong>{timeSince(winner.created)}</strong></div>
                <ClawButton href={prize.url} target="_blank" rel="noopener noreferrer">
                  View Prize
                </ClawButton>
              </SlideItem>
            </SplideSlide>
          ))
        )}
      </Splide>
    </CarouselContainer>
  );
};

export default RecentWinnersCarousel;
