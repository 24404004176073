import React, { useState, useRef, useEffect } from "react";
import { io, Socket } from 'socket.io-client';

import { LocalSocket } from "./types/LocalSocket"; // adjust import path if needed


import './App.css';



import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GradeIcon from '@mui/icons-material/Grade';
import PlayArrowIcon from '@mui/icons-material/PlayArrow'; // Icon for added arcade vibe

import GamePad from "./component/GamePad"
import RecentWinnersCarousel from "./component/RecentWinnersCarousel"

import StartCountdown, { StartCountdownHandle } from "./component/StartCountdown";

import SoundPlayer from "./component/SoundPlayer"

import PlayLimitPopup from "./component/PlayLimitPopup";



import PanToolIcon from "@mui/icons-material/PanTool"; // Represents grabbing action
import BackHandIcon from "@mui/icons-material/BackHand"; // Represents grabbing action
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';



import SwitchCameraIcon from "@mui/icons-material/SwitchCamera";
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import VideoWithLoading from "./component/VideoWithLoading";

import ReactPlayer from 'react-player'

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import AudioToggleButton from "./component/AudioToggleButton";
import ClawMovingSoundEffect from "./component/ClawMovingSoundEffect";
import QueueBanner from "./component/QueueBanner"
import Confetti from 'react-confetti-boom';

import { Browser } from '@capacitor/browser';
import {Capacitor} from '@capacitor/core'

//import { disposableEmailDomains } from './component/temporaryMailList';
import { allowableEmailDomains } from './component/allowMailList';


import {
  Box, useMediaQuery, useTheme,
  AppBar,
  Toolbar,
  Typography,
  Container,
  CssBaseline,
  Backdrop,
  Button,
} from "@mui/material";
import LightbulbThinking from "./component/LightBulkThinking";

  import D8Logo from "./assets/D8_logo_black.png"


  import { StatusBar } from '@capacitor/status-bar';

function App() {




  const queryParams = new URLSearchParams(window.location.search);

  // Retrieve specific query parameters
  const playerName = queryParams.get('firstName') || 'Guest';
  //const playerEmail = queryParams.get('email') || 'noemail@d8superstore.com';
  const playerEmail = queryParams.get('email') || '';
  const playerPoints = queryParams.get('points') || 0;

  const playerCity = queryParams.get('city') || 'Planet Earf';
  const playerState = queryParams.get('state') || '';
  let isDebug= queryParams.get('debug') == 'true' ? true : false;
  //navtive platform = debug
  if(Capacitor.isNativePlatform()){
    isDebug = true;
  }
  

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen size is mobile

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const [isOpenGameWinLoseDialog, setOpenGameWinLoseDialog] = useState<boolean>(false);
  const [gifUrl, setGifUrl] = useState<string>('');
  const [isPlayingClawMovingSoundEffect, setIsPlayingClawMovingSoundEffect] =
    useState<boolean>(false);
  const [isFirstGameStarted, setIsFirstGameStarted] =
    useState<boolean>(false);
  const [isPlayLimitPopupOpen, setPlayLimitPopupOpen] = useState<boolean>(false);

  const [showGrabThinking, setShowGrabThinking] = useState<boolean>(false)
  const [isAudioEnabled, setIsAudioEnabled] = useState<boolean>(false);
  const [hasWon, setHasWon] = useState<boolean>(false);
  const countdownRef = useRef<StartCountdownHandle>(null);

  const isPlayingBox = useRef<HTMLDivElement | null > (null);


  const [playSound, setPlaySound] = useState(false);
  const [soundUrl, setSoundUrl] = useState<string>('');
  const [winProductImageUrls, setWinProductImageUrls] = useState<string[]>([]);


  const handleAudioToggle = (value: boolean) => {
    setIsAudioEnabled(value);
    console.log("Audio is now:", value ? "Enabled" : "Disabled");
  };

  const [toggleCamera, setToggleCamera] = useState<string>('top')
  const [queuePosition, setQueuePosition] = useState<number>(0)
  const [queueTotal, setQueueTotal] = useState<number>(0)
  const [currentPlayerLocation, setCurrentPlayerLocation] = useState<string>('')

  const [useTopCamera, setUseTopCamera] = useState(false); // State to toggle between front and rear cameras


  // timer information
  type Direction = "up" | "down" | "left" | "right";
  const [times, setTimes ] = useState<Record<Direction, number>>({
    up: 0,
    down: 0,
    left: 0,
    right: 0,
  });


  const [currentDirection, setCurrentDirection] = useState<Direction | null>(null);
  const [debounceGrab, setDebounceGrab] = useState<Boolean>(false);
  const [timerStart, setTimerStart] = useState<number | null>(null);
  const maxTime = 3500; // Max time in milliseconds (4 seconds)

  const startTimer = (direction: Direction) => {
    if (currentDirection !== null) return; // Prevent multiple timers
    setCurrentDirection(direction);
    setTimerStart(Date.now());
  };

  const stopTimer = () => {
    if (currentDirection && timerStart) {
      let elapsedTime = Date.now() - timerStart;
      if (elapsedTime > maxTime) {
        elapsedTime = maxTime;
      }
      setTimes((prevTimes) => ({
        ...prevTimes,
        [currentDirection]: prevTimes[currentDirection] + elapsedTime,
      }));
      setCurrentDirection(null);
      setTimerStart(null);
    }
  };

  const togglePlayState = () => {
    setIsPlayingClawMovingSoundEffect(
      !isPlayingClawMovingSoundEffect
    ); // Toggle the play state
  };



  const socket = useRef<LocalSocket| null>(null); // Ref to hold the WebSocket instance



  // only suppport white listed email domains
  const isAllowableEmail= (email: string)=>{
    if(!email){
      return false
    }
    if(email.indexOf('@') < -1){
      return false
    }
    const domain = email.split('@')[1];
    return allowableEmailDomains.includes(domain);
  }
  


  useEffect(() => {
    // force end game after 33 seconds
    setTimeout(() => {
      if (isPlaying) {
        console.log('time up force end game')
        //grab()
      }
    }, 33000
    )
  }, [isPlaying]);

  const handlePlayLimitClosePopup = (): void => {
    setPlayLimitPopupOpen(false);
  };



  // INIT
  useEffect(() => {
    /*
    // hide status bar
    if(Capacitor.isNativePlatform()){

    StatusBar.hide();

    }
    */
    // if not email/user/debug in title, re-route to landing page
    const queryParams = new URLSearchParams(window.location.search);
    const debug = queryParams.get('debug') 

    //@ts-ignore
    const playerEmail:string = queryParams.get('email') 
    // bypass for native platform

    /*
    if(!isAllowableEmail(playerEmail)){
      alert('Sorry. Temporary emails are not supported. Please log out and log back in with a non-temporary email.')
      window.location.replace("https://d8superstore.com/claw");
      return
    }
      */
    if ( !playerEmail && debug !== 'true' && !Capacitor.isNativePlatform()){
      window.location.replace("https://d8superstore.com/claw");

    }
    
    // Establish the socket connection
    if (socket.current) {
      return
    }


    console.log('establishing socket ocnnect')
    //socket.current = io("http://127.0.0.1:5001"); // Replace with your server URL
    socket.current = io("https://claw-api.d8superstore.com") as LocalSocket; // Replace with your server URL



    socket.current.localData ={isPlaying: false, isPlayingTimeout: null};

    socket.current.on("connect", () => {


      console.log("Connected to server:", socket.current?.id);
    });

    socket.current.on("disconnect", () => {
      console.log("Disconnected from server");
    });



    /*
    // Listen for queue position updates
    socket.emit('leave_queue');
    */
    // Listen for room assignment
    socket.current.on('room_assigned', (data) => {
      console.log(`You are now playing in the room: ${data.room}`);
      countdownRef.current?.startCountdown();

      setIsFirstGameStarted(true)
      setIsPlaying((prevState: boolean): boolean => !prevState);
      //@ts-ignore
      socket.current.localData.isPlaying = true;
      sendCommand('start')

      // send back lose status if server hasn't replied yet, eg no internet, etc
      //@ts-ignore
      socket.current.localData.isPlayingTimeout = setTimeout(function(){
        let res = {'status': 'lose', 'auto_status': true}
        console.log('20 seconds testno there!!')
        processGameResults(res)
      }, 50000)
    });

    // Listen for queue position updates
    socket.current.on('queue_status', (data) => {
      setQueuePosition(data.position)
      setQueueTotal(data.total)

      /*
      if(data.position==1){
        alert(`Hi! You are next in line to play. Plesae be patient. The game will automatically start when it's your turn`)
      }
      else if(data.position>1){
        alert(`hi there are ${data.position} players ahead of you. Plesae be patient. The game will automatically start when it's your turn`)
      }
        */
      console.log(`Your position in the queue: ${data.position}`);
    });



    socket.current.on('current_player_info', (res) => {
      if (res) {
        //alert(JSON.stringify(res))
        console.log("GOT RESPONSE FROM SERVER FROM GRAB")
        let city = res.city? res.city : 'Planet Earf'
        let state = res.state? res.state : ''
        let local: string[] = [city, state]
        let localStr: string =local 
        .filter(item => item.trim() !== "") // Exclude empty or whitespace-only strings
        .join(", "); // Join with a comma and space
        setCurrentPlayerLocation(localStr)
      }
      })


    socket.current.on('game_status', (res) => {
      console.log('processing game results')
      processGameResults(res)
    })
    // Cleanup on unmount
    return () => {
      if (socket.current) {
        socket.current.disconnect();
        socket.current = null;
      }
    };
  }, []);



  // Listen for room assignment


    const triggerSound = (url: string): void => {
      setSoundUrl(url)
      setPlaySound(true)
      // reset sound
      setTimeout(() => setPlaySound(false), 5000);

    }

  const startGame = () => {
    // Join the queue
    console.log('starting game')
    if (!socket.current) {
      return

    }
    // send player information on player connect
    let playerObj = {
      'firstName': playerName,
      'email': playerEmail,
      'city': playerCity,
      'state': playerState,
      'username': 'Player1',
    }
    if (isDebug){
      //@ts-ignore
      playerObj.debug = true
    }


    console.log('test here has socket')
    socket.current.emit('join_queue', playerObj);


  }



  const sendCommand = async (command: string): Promise<Object> => {
    console.log('emitting command')

    socket.current?.emit('command', { command: command });
    return { command: command }
  }


  const handleDirection = async(command:string) =>{
    if(command == 'stop'){
      stopMovement()
    }
    if(command == 'left'){
      goLeft()
    }
    if(command == 'right'){
      goRight()
    }
    if(command == 'up'){
      goUp()
    }
    if(command == 'down'){
      goDown()
    }
    if(command == 'grab'){
      grab()
    }

  }
  const stopMovement = async () => {
    stopTimer()
    sendCommand('stop')
    setIsPlayingClawMovingSoundEffect(false)
  }
  const goLeft = async () => {
    startTimer('left')
    sendCommand('left')
    setIsPlayingClawMovingSoundEffect(true)
  }
  const goRight = async () => {
    startTimer('right')
    sendCommand('right')
    setIsPlayingClawMovingSoundEffect(true)
  }
  const goUp = async () => {
    startTimer('up')
    sendCommand('up')
    setIsPlayingClawMovingSoundEffect(true)
  }
  const goDown = async () => {
    startTimer('down')
    sendCommand('down')
    setIsPlayingClawMovingSoundEffect(true)
  }


    const processGameResults = (res:any)=>{
      // show grab thinking only if results have not yet been returned

      //@ts-ignore
      if(!socket && !socket.current){
        return
      }

      //@ts-ignore
      if(!socket.current.localData.isPlaying){
        //alert('timeout running ')
        console.log(' no timeout so return sliently')
        return
      }

      // added 3/17/25
      if (res.status) {
        //alert(JSON.stringify(res))
        console.log("GOT RESPONSE FROM SERVER FROM GRAB")
        setIsPlaying(false)
        setIsPlayingClawMovingSoundEffect(false)
      }else{
        console.log(' no longer playing so silently return')
        return
      }

      //@ts-ignore
      if (res.status === 'lose') {
        setOpenGameWinLoseDialog(true)
        setHasWon(false)
        triggerSound('./music/lose.mp3')
        setGifUrl('https://media.tenor.com/4pHRxPXHWxIAAAAj/loser-sticker-loser.gif')
        //alert(JSON.stringify(res))
        //@ts-ignore
      } else if (res.status === 'win') {
        setOpenGameWinLoseDialog(true)
        triggerSound('./music/win.mp3')
        setHasWon(true)
        setGifUrl('https://media1.tenor.com/m/QwyDTN_0AfAAAAAd/the-goon-win.gif')
        // todo make this defensive
        let prizes = res.prizes
        //alert(JSON.stringify(res))
        let images: string[] = []
        for (let x = 0; x < prizes.length; x++) {
          let prize = prizes[x]
          // TODO support multipele win product images
          images.push(prize.productImageUrl)
        }
        setWinProductImageUrls(images)
        //@ts-ignore
      } else if (res.status === 'exceededDailyQuota') {
        setIsPlaying(false)
        countdownRef.current?.cancelCountdown();
        setPlayLimitPopupOpen(true);
      } else if (res.status) {
        console.log('erorr , server sent back weird error ' + JSON.stringify(res))
      }
      setShowGrabThinking(false)
      //@ts-ignore
      socket.current.localData.isPlaying = false
      //@ts-ignore
      clearTimeout(socket.current.localData.isPlayingTimeout)

    }

  
  const grab = async () => {
    if(debounceGrab){
      console.log('debounced')
      return
    }
    
    stopTimer()
    sendCommand('grab')
    setIsPlayingClawMovingSoundEffect(true)
    setTimeout(function () {
      setShowGrabThinking(true)
    }, 2000)

    setTimeout(function () {

      // max claw moving to back to start is 7.97 seconds, so 8 secs
      setIsPlayingClawMovingSoundEffect(false)
    }, 8000)

    setDebounceGrab(true)
    setTimeout(function(){
      setDebounceGrab(false)
    }, 15000)




    // inspect timer and see if we know if auto loss
    // moved to server side
    let x = Math.abs(times['left'] - times['right'])

    let y = Math.abs(times['up'] - times['down'])
    //alert(x + ' ' +  y)
    if( x < 0.2 && y < 0.2){
    }
  }

  const ClawIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: 16,
    top: 16,
    backgroundColor: '#FFD700', // Vibrant yellow
    color: '#fff',
    boxShadow: '0px 0px 10px 2px rgba(255, 215, 0, 0.7)', // Yellow glow
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#FFE135', // Slightly lighter yellow on hover
      boxShadow: '0px 0px 15px 3px rgba(255, 215, 0, 1)',
    },
  }));

  return (
    <Box className="App"

      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Full height of the viewport
        //backgroundColor: '#212121',
        backgroundColor: '#373737'
      }}

    >

      {/* AppBar at the top */}
      <AppBar position="static" style={{
        'backgroundColor': '#FFC107',
        boxShadow: "none", // Remove the shadow
        width: '100vw',


      }}>
        <Toolbar
          style={{ paddingTop: '0.25em' }}
        >
          <Typography variant="h6" component="div"
            style={{ padding: 0 }}
            sx={{
              flexGrow: 1,
              textAlign: 'left'
            }}
          >
            <PrecisionManufacturingIcon style={{ fontSize: 40, color: 'black' }} />
            <img src={D8Logo} style={{ height: 30, padding: 5, paddingLeft: 1, paddingRight: 3, color: 'black' }} />
            <SportsEsportsIcon style={{ fontSize: 40, color: 'black' }} />
            <EmojiEventsIcon style={{ fontSize: 40, color: 'black' }} />
            <GradeIcon style={{ fontSize: 40, color: 'black' }} />


          </Typography>
          <IconButton
            onClick={() => setUseTopCamera((prev) => !prev)}
            size="large"
            style={{
              color: 'black',
              fontSize: "36px",
              //borderRadius: "50%",
              //boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CameraswitchIcon />
          </IconButton>


          <AudioToggleButton isFirstGameStarted={isFirstGameStarted} isAudioEnabled={isAudioEnabled} onAudioToggle={handleAudioToggle} />
        </Toolbar>
      </AppBar>


      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"} // Column for mobile, row for desktop
        width="100%"
        height="100%"
        sx={{
          flexGrow: 1, // Push the footer to the bottom if content is insufficient
        }}

      >
        <StartCountdown ref={countdownRef} />
        <Box
          flexShrink={0} // Prevent shrinking
          boxSizing="border-box"
          sx={{
            bgcolor: 'black',
          }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center" // Optional: Centers items vertically too

        >
          {showGrabThinking && (
              <LightbulbThinking  />
          )}
          <VideoWithLoading useTopCamera={useTopCamera} currentPlayerLocation ={currentPlayerLocation}/>
        </Box>
        {/* second layoubt box*/}
        <Box
          flex={1} // Take remaining space
          width="100%" // Full width
          p={2} // Padding
          boxSizing="border-box"
          sx={{
            display: "flex",           // Use flexbox
            flexDirection: "column",   // Optional: stack content vertically
            alignItems: "center",      // Center content vertically
            justifyContent: "center",  // Center content horizontally
            flexGrow: 1, // Push the footer to the bottom if content is insufficient
          }}

        >
          {!isPlaying ? (

            <Box ref={isPlayingBox} sx={{height: '100%', flexShrink: 1, maxWidth: isMobile? '100%': 'calc(100%-460px)', overflowX: 'hidden'}}>
                {isMobile? '': <div style={{paddingBottom: '3em'}}><RecentWinnersCarousel/></div>}
              <Typography variant="h6" sx={{ marginBottom: '1em', color: 'white' }}>

              </Typography>

              {queuePosition > 0 ? (

                <QueueBanner position={queuePosition} total={queueTotal} />
              ) : (

                <Box>
                  <Button className="gameButton"
                    onClick={() => startGame()}
                    variant="contained" // Filled button
                    color="primary" // Primary theme color
                    size="large" // Larger button size
                    startIcon={<PlayArrowIcon />} // Playful start icon
                    sx={{
                      background: "linear-gradient(135deg, #FFC107, #FF4081)", // Gradient for arcade look
                      color: "white", // Text color
                      fontWeight: "bold",
                      fontSize: "1.5rem", // Large, bold text
                      textTransform: "uppercase",
                      borderRadius: "50px", // Rounded button
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // Add arcade-style shadow
                      px: 5, // Horizontal padding
                      py: 2, // Vertical padding
                      '&:hover': {
                        background: "linear-gradient(135deg, #FF4081, #FFC107)", // Reverse gradient on hover
                        boxShadow: "0 6px 15px rgba(0, 0, 0, 0.4)", // Enhance shadow on hover
                      },
                    }}
                  >
                    START GAME
                  </Button>
                {isMobile? (<div style={{marginTop:'1em'}}><RecentWinnersCarousel/></div>): ''}
                </Box>
              )}
            </Box>
          )

            : (


              <Box>
              <GamePad   direction= {handleDirection} stopMovement={stopMovement}/>
              <br/>
              <br/>
              <br/>
              </Box>

            )}
        </Box>

        <PlayLimitPopup open={isPlayLimitPopupOpen} onClose={handlePlayLimitClosePopup} />


        <ClawMovingSoundEffect
          isPlayingClawMovingSoundEffect={isPlayingClawMovingSoundEffect}
          isAudioEnabled={isAudioEnabled}
        />

        <SoundPlayer
          soundUrl={soundUrl}
          isAudioEnabled={isAudioEnabled}
          playSound={playSound}
        />

        <Dialog
          maxWidth={'xl'}

          slots={{
            backdrop: Backdrop, // Specify the Backdrop component
          }}
          PaperProps={{
            sx: {
              backgroundColor: 'black', // Set backdrop background color to black with transparency
              marginLeft: 0,
              marginRight: 0,
              paddingLeft: '32px',
              paddingRight: '32px',
              overflowX: 'hidden',
              scrollbarWidth: 'none', // Hide scrollbar (Firefox)
              '&::-webkit-scrollbar': {
                display: 'none', // Hide scrollbar (Chrome, Safari, Edge)
              }

            },
          }}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: "rgba(0, 0, 0, 0.96)", // Black overlay
              },
            },
          }}

          onClose={() => setOpenGameWinLoseDialog(false)} open={isOpenGameWinLoseDialog}>
          <DialogTitle

            sx={{ backgroundColor: 'black' }}
          >
            <ClawIconButton aria-label="close"
              onClick={() => setOpenGameWinLoseDialog(false)}
            >
              <CloseIcon />
            </ClawIconButton>
          </DialogTitle>




          <DialogContent
            sx={{ backgroundColor: 'black' }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            >

              <img style={{
                'width': isMobile ? "auto" : "auto",
                'maxHeight': '30vh',
                'maxWidth': '70vw',
                borderRadius: '0.5em'

              }} src={gifUrl} />

            </Box>
            {hasWon && (
              <Box>
                <Typography variant="h6" sx={{ marginTop: '2em', color: 'white' }}>
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                  }}
                >

                {winProductImageUrls.map((imageUrl, index)=>(

                  <Box
                  sx={{
                    margin: '1em',
                  }}
                  
                  >


                  <img 
                  style={{
                    'width': isMobile ? "auto" : "400px",
                    'maxWidth': '50vw', marginTop: '2em',
                    'maxHeight': '40vh',
                    borderRadius: '0.5em'

                  }} src={imageUrl} />
                  <br/>
                  </Box>

                ))
                }


                </Box>
                <br />
                <Confetti mode="boom" particleCount={90} effectCount={3} y={isMobile ? 0.45 : 0.5} x={isMobile ? 0.5 : 0.25} spreadDeg={40} shapeSize={12} colors={['#ff577f', '#ff884b']} />
                <Typography variant="h6" sx={{
                  color: 'white',
                }}>
                  Please check your email for a coupon code to redeem prize(s) on d8superstore.com. If you do not receive an email with the coupon code, please check your spam folder. 


                </Typography>
              </Box>

            )}
          </DialogContent>
        </Dialog>
      </Box>


      <Box
        component="footer"
        sx={{
          bgcolor: "#FFC107",
          color: "black",
          py: 2,
          textAlign: "right",
        }}
      >
        <Typography variant="body2" sx={{ paddingRight: '2em' }}>
          © {new Date().getFullYear()} Claw Machine Goes Rawr by D8 Super Store. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default App;
