import React, { useState } from "react";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import GrabIcon from "./GrabIcon";

import {
  Box, useMediaQuery, useTheme, Button
} from "@mui/material";

type Direction = "up" | "down" | "left" | "right";


// Props type definition
interface GamePadProps{
  direction: (direction:string) => void;
  stopMovement: () => void;
}

const GamePad: React.FC<GamePadProps> = ({  direction, stopMovement}) => {
//const GamePad: React.FC = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen size is mobile



  const buttonStyles = {
    borderRadius: "50%", // Circular buttons
    width: isMobile ? "80px" : "90px",
    height: isMobile ? "80px" : "90px",
    margin: 'auto',
    fontSize: "1.5rem",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.4)", // Arcade-style glow
    background: "linear-gradient(135deg, #FFC107, #FF4081)", // Vibrant arcade colors
    color: "white",
    "&:hover": {
      background: "linear-gradient(135deg, #FF4081, #FFC107)", // Reverse gradient on hover
      boxShadow: "0 6px 20px rgba(255, 64, 129, 0.8)", // Stronger glow on hover
    },
  };

  const iconStyle = {
    fontSize: "2.5rem", // Increase the icon size
    fontWeight: "bold", // Make icons visually stronger
    stroke: "white", // Add stroke for extra boldness
    strokeWidth: 1.5, // Make the stroke thicker
  };

  const grabButtonStyle = {
    fontWeight: "normal",
    ...buttonStyles,
    fontSize: "1.5rem",
    background: "linear-gradient(135deg, #32cd32, #00bfff)", // Unique color for grab button
    "&:hover": {
      background: "linear-gradient(135deg, #00bfff, #32cd32)", // Reverse gradient
      boxShadow: "0px 6px 20px rgba(0, 191, 255, 0.8)", // Glow effect for grab button
    },
  };



  return (

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: isMobile ? 3 : 5,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >

                <Button className="gameButton" variant="contained" size="large"
                  onMouseDown={()=>direction('up')} onTouchStart={()=>direction('up')}
                  onMouseUp={stopMovement} onTouchEnd={stopMovement}
                  sx={{ ...buttonStyles, gridColumn: "2" }}
                >
                  <ArrowUpwardIcon sx={iconStyle} />
                </Button>
                <br />

                <br />
                <Button className="gameButton" variant="contained" size="large"

                  onMouseDown={()=>direction('left')} onTouchStart={()=>direction('left')}
                  onMouseUp={stopMovement} onTouchEnd={stopMovement}
                  sx={{ ...buttonStyles, gridColumn: "1" }}
                >
                  <ArrowBackIcon sx={iconStyle} />

                </Button>


                {/* Grab Button */}
                <Button
                  onMouseDown={()=>direction('grab')} onTouchStart={()=>direction('grab')}
                  variant="contained"
                  sx={{
                    width: "90px",
                    height: "90px",
                    borderRadius: "50%",
                    background: "linear-gradient(135deg, #32cd32, #00bfff)",
                    color: "white",
                    "&:hover": {
                      background: "linear-gradient(135deg, #00bfff, #32cd32)",
                      boxShadow: "0px 6px 20px rgba(0, 191, 255, 0.8)",
                    },
                    gridColumn: "2"
                  }}
                >
                  <GrabIcon />
                </Button>



                <Button className="gameButton"
                  onMouseDown={()=>direction('right')} onTouchStart={()=>direction('right')}
                  onMouseUp={stopMovement} onTouchEnd={stopMovement}
                  sx={{ ...buttonStyles, gridColumn: "3" }}
                >
                  <ArrowForwardIcon sx={iconStyle} />
                </Button>


                <br />
                <br />
                <Button className="gameButton" variant="contained" size="large"
                  onMouseDown={()=>direction('down')} onTouchStart={()=>direction('down')}
                  onMouseUp={stopMovement} onTouchEnd={stopMovement}
                  sx={{ ...buttonStyles, gridColumn: "2", gridRow: "3" }}
                >
                  <ArrowDownwardIcon sx={iconStyle} />

                </Button>


              </Box>
  );
};

export default GamePad;
